import { FormField, TextInput } from './Components'

export default function BankData({ data }) {
  return (
    <>
      <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
        <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
          Bank Details
        </p>
      </div>

      <FormField label="Account Number">
        <TextInput
          placeholder="xxxx-xxxx-xxxx"
          name="account_number"
          required
          defaultValue={data.account_number}
        />
      </FormField>

      <FormField label="IFSC CODE">
        <TextInput
          placeholder="19230"
          name="ifsc_code"
          required
          defaultValue={data.ifsc_code}
        />
      </FormField>

      <FormField label="Beneficiary Name (Same as Legal Business Name)">
        <TextInput
          placeholder="ABC corp"
          name="beneficiary_name"
          required
          defaultValue={data.beneficiary_name}
        />
      </FormField>

      {/* <Modal open={open} onClose={() => setOpen(false)}>
        <div>d</div>
      </Modal> */}
    </>
  )
}
