import { useEffect, useState } from 'react'
import { getApiReq } from '../utils/ApiHandler'
import { showToast } from '../redux/action'
import { useDispatch } from 'react-redux'
import BankData from './form/BankData'
import { useHistory } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'
import MerchantsDataStep1 from './form/MerchantsData'
import StakeholderData from './form/StakeholderData'

export default function MerchantsData() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [merchant, setMerchant] = useState({})
  const [steps, setSteps] = useState(1)

  function handlePrev() {
    if (steps > 1) {
      setSteps(steps - 1)
    }
  }

  function handleNext() {
    if (steps < 3) {
      setSteps(steps + 1)
    }
  }

  useEffect(() => {
    getApiReq(`/merchant/get-merchant-detail`).then((res) => {
      if (res.status) {
        setMerchant(res.data.data?.[0] ?? {})
      } else {
        dispatch(showToast({ type: 'error', message: res.error }))
      }
    })
  }, [dispatch])

  return (
    <>
      <div className="bg-gray-200 min-h-screen w-full text-[18px] py-12 pt-4 relative">
        <form method="post" action="#" onSubmit={(e) => e.preventDefault()}>
          <div className="max-w-[1200px] mx-auto px-4 flex items-center justify-between">
            <button onClick={() => history.push('/MerchantList')}>
              <IoMdArrowRoundBack className="text-[30px]" />
            </button>
          </div>
          <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] gap-20 mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center flex-col">
            <div className="gap-6 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              {steps === 1 && <MerchantsDataStep1 data={merchant} />}

              {steps === 2 && (
                <StakeholderData
                  data={
                    typeof merchant.stakeholder_details === 'string'
                      ? JSON.parse(merchant.stakeholder_details)
                      : merchant.stakeholder_details ?? {}
                  }
                />
              )}

              {steps === 3 && (
                <BankData
                  data={
                    typeof merchant.bank_details === 'string'
                      ? JSON.parse(merchant.bank_details)
                      : merchant.bank_details ?? {}
                  }
                />
              )}

              <div className="w-full">
                <div className="flex gap-6 w-fit ml-auto">
                  <button
                    disabled={steps === 1}
                    type={'button'}
                    onClick={handlePrev}
                    className="px-5 py-3 border border-black capitalize disabled:opacity-20"
                  >
                    prev
                  </button>

                  <button
                    type={'button'}
                    disabled={steps === 3}
                    onClick={handleNext}
                    className="px-5 py-3 border border-black capitalize disabled:opacity-20"
                  >
                    next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
