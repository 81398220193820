import Navbar from '@components/layout/Navbar';
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import moment from "moment";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { StartEndDateValidation } from "../utils/auth";
import { isYupError, parseYupError } from "../utils/Yup";

const PaymentData = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [payment, setPayment] = useState();
  const [errors, setErrors] = useState();
  const [date, setDate] = useState({
    Start: '',
    End: ''
  });

  const getPayment = () => {

    getApiReq(`/merchant/get-payment?startDate=${date.Start}&endDate=${date.End}`).then((res) => {
      if (res.status) {
        setPayment(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }

  const handleChange = (name, e) => {
    setDate({ ...date, [name]: moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss a") })
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getCouponData = async () => {
    try {
      setErrors({});
      await StartEndDateValidation.validate(date, {
        abortEarly: false,
      });
      getPayment()

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  }

  useEffect(() => {
    getPayment()
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex tablet:flex-row flex-col tablet:p-4 p-3 justify-between bg-[#CCCCCC]">

        <div className='flex items-center'>
          <div class="flex gap-5 h-[60px]">
            <div>
              <input class="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="Start" type="datetime-local"
                onChange={(e) => handleChange("Start", e)} />
              <p className="tablet:text-[16px] text-[13px] pl-3">
                {errors ? (
                  <span style={{ color: "red" }}>
                    {errors.Start}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            <div>

              <input class="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="End" type="datetime-local"
                onChange={(e) => handleChange("End", e)} />
              <p className="tablet:text-[16px] text-[13px] pl-3">
                {errors ? (
                  <span style={{ color: "red" }}>
                    {errors.End}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            <button onClick={getCouponData} className="bg-gray-600 text-white font-semibold h-[40px]">Search</button>
          </div>
        </div>
      </div>


      <div className="">
        <table className="tablet:w-[200%] lg:w-[100%] ">
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchants Name</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Total Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchant Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Inburg Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Discount Value</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Id</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Order Id</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Status</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Settlement - In progress</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Settlement - Done</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Date</th>
            </tr>
          </thead>
          <tbody>
            {payment?.length > 0 ?
              payment?.map((item, i) => (

                <tr key={i} className="tablet:text-[18px] text-[14px]">

                  {/* <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                        <button className="text-sky-700 capitalize text-[20px]" onClick={() => navigate(`/couponsviewpage/${item.merchant_id}`)}>{item.merchant_name}</button>
                      </td> */}
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.merchant_name}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.amount) ? item.amount : 0}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.merchant_amount) ? item.merchant_amount : 0}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.inburg_amount) ? item.inburg_amount : 0}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.offer_value ? item.offer_value : 'U/A'}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.razorpay_payment_id ? item.razorpay_payment_id : 'U/A'}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.razorpay_order_id ? item.razorpay_order_id : 'U/A'}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_status ? item.payment_status : 'U/A'}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_date ? moment(new Date(item.payment_date)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>
                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.merchant_on_hold_until ? moment(new Date(item.merchant_on_hold_until)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>

                  <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_date ? moment(new Date(item.payment_date)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>
                </tr>

              )) :
              <tr className="tablet:text-[18px] text-[14px]">
                <td colSpan={11} className=" text-center border-b-2 border-gray-300 py-2">Data Not Found</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}


export default PaymentData
