import ENV from '@utils/env'
import { useEffect, useState } from 'react'
import { businessCategories } from './businessCategoriesData'
import { FormField, RadioInput, SelectInput, TextInput } from './Components'

export default function MerchantsDataStep1({ data }) {
  const categories = businessCategories.filter(
    (category) => category.parent_id === 0
  )

  const defaultAddress = data?.address
  const defaultLegalInfo = data?.legal_info

  const defaultCategory = businessCategories.find(
    (category) => category.value === data.category
  )

  const [category, setCategory] = useState({
    id: 1,
    label: 'Financial Services',
    value: 'financial_services',
    parent_id: 0,
  })

  useEffect(() => {
    if (defaultCategory) {
      setCategory({ ...defaultCategory })
    }
  }, [defaultCategory])

  const subCategories = businessCategories.filter(
    (subcategory) => subcategory.parent_id === category.id
  )

  console.log(data?.category)

  return (
    <>
      <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
        <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
          Create New Merchant Or Store Entity
        </p>
      </div>

      {data?.logo && (
        <FormField label={'Logo'}>
          <img
            alt="logo"
            className="w-20 h-20"
            src={`${ENV.IMAGE_URL}merchant_logo/${data?.logo}`}
          />
        </FormField>
      )}

      <FormField label={'Email address'}>
        <TextInput
          type="email"
          placeholder="name@example.com"
          name="email"
          value={data?.email}
        />
      </FormField>

      <FormField label={'Phone'}>
        <TextInput
          type="text"
          placeholder="+912212345678"
          name="phone"
          value={data?.phone}
        />
      </FormField>

      <FormField label={'Legal Business Name'}>
        <TextInput
          type="text"
          placeholder="ABC Corp"
          name="legal_business_name"
          value={data?.name}
        />
      </FormField>

      <FormField label={'UID'}>
        <TextInput
          type="text"
          placeholder="123"
          name="uid"
          value={data?.uid}
          disabled
        />
      </FormField>

      <FormField label={'UMID'}>
        <TextInput
          type="text"
          placeholder="123"
          name="umid"
          value={data.umid}
          disabled
        />
      </FormField>

      {data.url && (
        <FormField label={'URL'}>
          <TextInput
            type="text"
            placeholder="https://inburg.com/merchants"
            name="url"
            value={data.url}
            disabled
          />
        </FormField>
      )}
      <FormField label={'Brand Name'}>
        <TextInput
          type="text"
          placeholder="Name"
          name="brand_name"
          value={data?.legal_info?.brand_name}
        />
      </FormField>

      <FormField label={'Status'}>
        <RadioInput
          items={[
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' },
          ]}
          name="status"
          value={String(data?.status)}
        />
      </FormField>

      <FormField label={'Business Type'}>
        <SelectInput
          name="business_type"
          items={business_type}
          value={data?.legal_info?.business_type}
        />
      </FormField>

      <FormField label={'Contact Person Name'}>
        <TextInput
          type="text"
          placeholder="John Doe"
          name="contact_name"
          value={data?.legal_info?.contact_name}
        />
      </FormField>

      <FormField label={'Package'}>
        <SelectInput name="package" items={packages} value={data?.package} />
      </FormField>

      <FormField label={'Category'}>
        <SelectInput
          value={data?.category}
          onChange={(e) => {
            const category = businessCategories.find(
              (category) => category.value === e.target.value
            )
            setCategory(category)
          }}
          name="category"
          items={categories.map((category) => {
            return {
              label: category.label,
              value: category.value,
            }
          })}
        />
      </FormField>

      <FormField label={'Sub Category'}>
        <SelectInput
          name="subcategory"
          value={data?.subcategory}
          items={subCategories.map((category) => {
            return {
              label: category.label,
              value: category.value,
            }
          })}
        />
      </FormField>

      <FormField label={'Address'}>
        <FormField>
          <TextInput
            type="text"
            placeholder="Street 1"
            name="address[street1]"
            value={defaultAddress?.street1}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="Street 2"
            name="address[street2]"
            value={defaultAddress?.street2}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="City"
            name="address[city]"
            value={defaultAddress?.city}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="State"
            name="address[state]"
            value={defaultAddress?.state}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="Postal Code"
            name="address[postal_code]"
            value={defaultAddress?.postal_code}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="Country(IN)"
            name="address[country]"
            value="IN"
            disabled
          />
        </FormField>
      </FormField>

      <FormField label={'Legal Info'}>
        <FormField>
          <TextInput
            type="text"
            placeholder="Business PAN"
            name="business_pan"
            value={defaultLegalInfo?.pan}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="GST"
            name="gst"
            value={defaultLegalInfo?.gst}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="POC Name"
            name="poc_name"
            value={data?.poc_name}
          />
        </FormField>

        <FormField>
          <TextInput
            type="text"
            placeholder="UPI"
            name="upi"
            value={data?.upi}
          />
        </FormField>
      </FormField>
    </>
  )
}

const business_type = [
  {
    value: 'llp',
    label: 'LLP',
  },
  {
    value: 'ngo',
    label: 'NGO',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'partnership',
    label: 'Partnership',
  },
  {
    value: 'proprietorship',
    label: 'Proprietorship',
  },
  {
    value: 'public_limited',
    label: 'Public Limited',
  },
  {
    value: 'private_limited',
    label: 'Private Limited',
  },
  {
    value: 'Trust,society',
    label: 'Trust/Society',
  },
  {
    value: 'not_yet_registered',
    label: 'Not Yet Registered',
  },
  {
    value: 'educational_institutes',
    label: 'Educational Institutes',
  },
]

const packages = [
  { value: 'free', label: 'Free' },
  { value: 'starter', label: 'Starter' },
  { value: 'advance', label: 'Advance' },
  { value: 'elite', label: 'Elite' },
]
